<template>
    <section class="table-data">

      <!--   <b-field grouped group-multiline>
            <div class="control">
                <b-switch v-model="showDetailIcon">Show detail icon</b-switch>
            </div>
            <div class="control">
                <b-switch v-model="useTransition">Use transition (fade) when toggling details</b-switch>
            </div>
        </b-field> -->

        <b-table
            :data="data"
            ref="table"
            paginated
            per-page="50"
            :opened-detailed="defaultOpenedDetails"
            detailed
            detail-key="id"
            :detail-transition="transitionName"
            @details-open="(row) => $buefy.toast.open(`Expanded ${row.user.fullName}`)"
            :show-detail-icon="showDetailIcon"
            aria-next-label="Next page"
            aria-previous-label="Previous page"
            aria-page-label="Page"
            aria-current-label="Current page">


            <b-table-column field="user.first_name" label="Judge Name" sortable v-slot="props">
                <template v-if="showDetailIcon">
                    {{ props.row.fullName }}
                </template>
                <template v-else>
                    <a @click="props.toggleDetails(props.row.fullName)">
                        {{ props.row.fullName }}
                    </a>
                </template>
            </b-table-column>

            <b-table-column field="user.last_name" label="Appointment Date" sortable v-slot="props">
                {{ props.row.appointDate }}
            </b-table-column>

             <b-table-column field="user.last_name" label="Retirement Date" sortable v-slot="props">
                {{ props.row.appointDate }}
            </b-table-column>

             <b-table-column field="user.last_name" label="Tenure" sortable v-slot="props">
                {{ props.row.appointDate }}
            </b-table-column>

              <b-table-column field="user.last_name" label="Judgement" sortable v-slot="props">
                {{ props.row.appointDate }}
            </b-table-column>



            <template #detail="props">
                <article class="media">
                    <figure class="media-left">
                        <p class="image is-64x64">
                            <img src="/static/img/placeholder-128x128.png">
                        </p>
                    </figure>
                    <div class="media-content">
                        <div class="content">
                            <p>
                                <strong>{{ props.row.user.first_name }} {{ props.row.user.last_name }}</strong>
                                <small>@{{ props.row.user.first_name }}</small>
                                <small>31m</small>
                                <br>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                Proin ornare magna eros, eu pellentesque tortor vestibulum ut.
                                Maecenas non massa sem. Etiam finibus odio quis feugiat facilisis.
                            </p>
                        </div>
                    </div>
                </article>
            </template>
        </b-table>

    </section>
</template>

<script>
const axios = require('axios');
    // const data = require('@/data/sample.json')

    export default {
       name: 'Judges',
        data() {
            return {
                // data,
                isPaginated: true,
                  isPaginationSimple: false,
                  isPaginationRounded: false,
                  paginationPosition: 'bottom',
                  defaultSortDirection: 'asc',
                defaultOpenedDetails: [1],
                showDetailIcon: true,
                useTransition: false,
                JudgeMents:'Judgements',
                  perPage: 50,
                  data: [],
                 columns: [
                    {
                        field: 'id',
                        label: 'ID',
                        width: '100',
                        numeric: true,
                        searchable: true,
                    },
                    {
                        field: 'first_name',
                        label: 'First Name',
                        searchable: true,
                    },
                    {
                        field: 'last_name',
                        label: 'Last Name',
                        searchable: true,
                    },
             ],
            }
        },

    mounted (){
          this.getLatestJudgement();
    },
        computed: {
          /*   transitionName() {
                if (this.useTransition) {
                    return 'fade'
                }
            } */
        },
        methods:{
             getLatestJudgement() {
                const loadingComponent = this.$buefy.loading.open({
                            container: this.isFullPage
                })
                axios.get('https://staging.spotlawapp.com/newapi/browseByJudges.php').then(res => {
                    this.data = res.data.judges;
                    loadingComponent.close();
                })
        },
        clickedTable(){
            console.log('clickedTable');
        }
     }
    }
</script>